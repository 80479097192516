<template>
	<div>
		<CCard>
			<CCardBody>
				<CRow>
					<CCol xl="12">
						<ClinicForm
							is-update
							@apply-form="updateClinic"
							@cancel-form="goToClinicList"
							@delete-form="deleteClinic"
						></ClinicForm>
					</CCol>
				</CRow>
			</CCardBody>
		</CCard>
	</div>
</template>

<script>
import { GET_CLINIC_BY_ID, UPDATE_CLINIC, DELETE_CLINIC, GET_CITIES } from "./../store/action-types"
import { createNamespacedHelpers } from "vuex"
const { mapActions } = createNamespacedHelpers("clinics")
export default {
	name: "UpdateClinic",

	components: {
		ClinicForm: () => import("./../components/ClinicForm.vue"),
	},

	props: {
		id: {
			type: [Number, String],
			default: null,
		},
		isUpdate: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		return {}
	},

	computed: {},

	watch: {},

	async created() {
		await this.GET_CITIES()
		this.GET_CLINIC_BY_ID({ id: this.id })
	},

	methods: {
		...mapActions({ GET_CLINIC_BY_ID, UPDATE_CLINIC, DELETE_CLINIC, GET_CITIES }),

		updateClinic: async function (params) {
			await this.UPDATE_CLINIC(params)
		},

		deleteClinic: async function (params) {
			await this.DELETE_CLINIC(params)
		},

		goToClinicList() {
			this.$router.push({ name: "ClinicList" })
		},
	},
}
</script>

<style lang="scss" scoped></style>
